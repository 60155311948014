<template>
  <ValidationObserver ref="observer" tag="div" v-slot="{ handleSubmit }">
    <form @submit.prevent="handleSubmit(validate)">
        <ValidationProvider v-slot="{ errors }" tag="div" rules="required" class="mb-2">
          <BaseInput type="text" label="Name" name="name" v-model="payload.name" />
          <p class="text-sm text-red-600" v-if="errors[0]">{{ errors[0] }}</p>
        </ValidationProvider>

         <ValidationProvider v-slot="{ errors }" tag="div" rules="" class="mb-2">
          <BaseInput type="text" label="Class" name="class" v-model="payload.class" />
          <p class="text-sm text-red-600" v-if="errors[0]">{{ errors[0] }}</p>
        </ValidationProvider>

      <ValidationProvider v-slot="{ errors }" tag="div" rules="required" class="mb-2">
          <BaseSelect label="Colour" name="colour" :options="colours" v-model="payload.colour" />
          <p class="text-sm text-red-600" v-if="errors[0]">{{ errors[0] }}</p>
        </ValidationProvider>

        <ValidationProvider v-slot="{ errors }" tag="div" rules="required" class="mb-2">
          <BaseSelect label="Who can see this area?" name="global" :options="globals" v-model="payload.global" />
          <p class="text-sm text-red-600" v-if="errors[0]">{{ errors[0] }}</p>
        </ValidationProvider>

      <BaseBtn type="submit" :text="payload.id ? 'Update' : 'Add'" />
      <FlashMessage :message="message" :error="error" />
    </form>
  </ValidationObserver>
</template>

<script>
import {
  extend,
  ValidationObserver,
  ValidationProvider,
  //setInteractionMode,
} from "vee-validate";
import { required, email } from "vee-validate/dist/rules";

extend("required", {
  ...required,
  message: "This field is required",
});

import { mapGetters } from "vuex";
import { getError } from "@/utils/helpers";
import BaseBtn from "@/components/BaseBtn";
import BaseInput from "@/components/BaseInput";
import AreaService from "@/services/AreaService";
import FlashMessage from "@/components/FlashMessage";
import BaseSelect from "@/components/BaseSelect";

export default {
  name: "AuthUserForm",
  components: {
    BaseBtn,
    BaseInput,
    BaseSelect,
    FlashMessage,
    ValidationProvider,
    ValidationObserver,
  },
  props: {
    attributes: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      payload: {
        name: "",
        colour: "",
        global: '0',
        class: ''
      },
      colours: [
        { value: "Red", key: "text-red-700" },
        { value: "Green", key: "text-green-700" },
        { value: "Blue", key: "text-blue-700" },
        { value: "Yellow", key: "text-yellow-700" },
      ],
      globals: [
        { value: "Just me", key: "0" },
        { value: "Everyone", key: "1" },
      ],
      error: null,
      message: null,
    };
  },
  computed: {
    ...mapGetters("area", ["add", "update"]),
  },
  methods: {
    validate() {
      this.error = null;
      this.message = null;
      console.log("this.payload", this.payload);

      if (this.payload.id) {
        AreaService.update(this.payload)
          .then(() => {
            this.message = "area updated.";
            this.$parent.close();
            this.$eventBus.$emit("areas:updated", true);
          })
          .catch((error) => (this.error = getError(error)));
      } else {
        AreaService.add(this.payload)
          .then(() => {
            this.message = "area added.";
            this.$parent.close();
            this.$eventBus.$emit("areas:added", true);
          })
          .catch((error) => (this.error = getError(error)));
      }
    },
  },
  mounted() {
    if (this.attributes.payload) {
      this.payload = this.attributes.payload;
    }
  },
};
</script>
