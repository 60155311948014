var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ValidationObserver',{ref:"observer",attrs:{"tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('form',{on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.validate)}}},[_c('ValidationProvider',{staticClass:"mb-2",attrs:{"tag":"div","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('BaseInput',{attrs:{"type":"text","label":"Name","name":"name"},model:{value:(_vm.payload.name),callback:function ($$v) {_vm.$set(_vm.payload, "name", $$v)},expression:"payload.name"}}),(errors[0])?_c('p',{staticClass:"text-sm text-red-600"},[_vm._v(_vm._s(errors[0]))]):_vm._e()]}}],null,true)}),_c('ValidationProvider',{staticClass:"mb-2",attrs:{"tag":"div","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('BaseInput',{attrs:{"type":"text","label":"Class","name":"class"},model:{value:(_vm.payload.class),callback:function ($$v) {_vm.$set(_vm.payload, "class", $$v)},expression:"payload.class"}}),(errors[0])?_c('p',{staticClass:"text-sm text-red-600"},[_vm._v(_vm._s(errors[0]))]):_vm._e()]}}],null,true)}),_c('ValidationProvider',{staticClass:"mb-2",attrs:{"tag":"div","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('BaseSelect',{attrs:{"label":"Colour","name":"colour","options":_vm.colours},model:{value:(_vm.payload.colour),callback:function ($$v) {_vm.$set(_vm.payload, "colour", $$v)},expression:"payload.colour"}}),(errors[0])?_c('p',{staticClass:"text-sm text-red-600"},[_vm._v(_vm._s(errors[0]))]):_vm._e()]}}],null,true)}),_c('ValidationProvider',{staticClass:"mb-2",attrs:{"tag":"div","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('BaseSelect',{attrs:{"label":"Who can see this area?","name":"global","options":_vm.globals},model:{value:(_vm.payload.global),callback:function ($$v) {_vm.$set(_vm.payload, "global", $$v)},expression:"payload.global"}}),(errors[0])?_c('p',{staticClass:"text-sm text-red-600"},[_vm._v(_vm._s(errors[0]))]):_vm._e()]}}],null,true)}),_c('BaseBtn',{attrs:{"type":"submit","text":_vm.payload.id ? 'Update' : 'Add'}}),_c('FlashMessage',{attrs:{"message":_vm.message,"error":_vm.error}})],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }